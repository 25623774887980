<template>
  <div>
    <el-header>
      <div class="logo">
        <img src="../../assets/images/logo.png" />
      </div>
      <div class="topTime">{{ current_date }} {{ week }}</div>
    </el-header>
  </div>
</template>

<script>
export default {
  data () {
    return {
      current_date: '',
      week: '',
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.token = localStorage.getItem('token')
      this.axios
        .get('/api/member/view_self', {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          this.current_date = res.data.current_date
          this.week = res.data.week
        })
    },
  },
}
</script>

<style scoped>
.el-header {
  background: linear-gradient(90deg, #1131ff 0%, #005dff 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.logo img {
  width: 55%;
  height: 55%;
}
</style>