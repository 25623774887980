<template>
  <div class="body">
    <!-- 引用模板新增 -->
    <Head />
    <Header class="title" back="返回" title="新增引用" index="首页" beforeTitle="新增/编辑" />
    <div class="content">
      <el-form label-position="top" ref="form" :model="quoteform" label-width="80px">
        <div class="from_upload">
          <el-form-item label="模板名称" style="width: 23%;">
            <el-input v-model="quoteform.name" placeholder="请输入模板名称"></el-input>
          </el-form-item>
          <div class="upload">
            <el-upload
              class="upload-demo"
              ref="imgupload"
              action="string"
              :http-request="httpRequestQuote"
              :show-file-list="false"
              :file-list="fileList"
            >
              <el-button :loading="loadingFile" type="primary" class="ChooseFile">请上传文件进行识别</el-button>
            </el-upload>
            <span style="color: #A6ABC7;margin-left: 20px;">（上传文件格式支持word，excel）</span>
            <span v-if="select_file_name">{{ select_file_name }}</span>
          </div>
        </div>
        <div class="from_flex">
          <el-form-item label="生成文件名称">
            <el-input v-model="quoteform.new_file_name" placeholder="请输入生成文件名称"></el-input>
          </el-form-item>
          <el-form-item label="生成文件类型格式">
            <el-select v-model="quoteform.type" placeholder="请选择文件类型格式">
              <el-option label="word" value="word"></el-option>
              <el-option label="excel" value="excel"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数据填充类型">
            <el-select v-model="quoteform.data_type" placeholder="请选择数据填充类型">
              <el-option label="表格" :value="1"></el-option>
              <el-option label="替换" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="quoteform.status" placeholder="请选择状态类型">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数据获取形式">
            <el-select
              @change="getQuoteType"
              v-model="quoteform.data_source"
              placeholder="请选择数据获取形式"
            >
              <el-option label="简单" :value="1"></el-option>
              <el-option label="复杂" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="simple">
          <el-form-item label="条件">
            <el-input v-model="quoteform.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="字段">
            <el-input v-model="quoteform.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="主表">
            <el-input v-model="quoteform.table" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="关联表">
            <el-input v-model="quoteform.join" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="quoteform.order" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="起始数">
            <el-input v-model="quoteform.limit" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="数量">
            <el-input v-model="quoteform.limit" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="填充字段" v-if="complex">
          <el-input v-model="quoteform.complex_function" placeholder="请输入"></el-input>
        </el-form-item>
        <h1>数据字段</h1>
        <el-table ref="tableRef" :data="quoteform.quote_char" style="width: 100%">
          <el-table-column width="50">
            <template slot="header">
              <button type="button" @click="addLine" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                type="button"
                v-if="quoteform.quote_char.length > 0"
                @click="handleDelete(scope.$index, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <el-table-column label="字段名">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" placeholder="请输入字段名"></el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" v-if="meber_id" width="70%">
          <template slot-scope="scope">
            <el-button class="cz info edit" @click="delEdit(scope.row.id)" type="text">编辑</el-button>
          </template>
          </el-table-column>-->
        </el-table>
      </el-form>
      <div style="text-align: center;">
        <el-button style="margin: 20px 0;" class="search" @click="onSubmit()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Head from '../../components/headBlue/index.vue'
export default {
  data () {
    return {
      loadingFile: false,
      fileList: [],
      select_file_name: '',
      quoteform: {
        quote_char: [],
        url: '',
        new_file_name: "",
      },
      simple: false,//简单类型
      complex: false,//复杂类型
    }
  },
  components: {
    Head,
  },
  created () {
    if (this.$route.query.id) {
      this.getView()
    }
  },
  methods: {
    httpRequestQuote (param) {
      this.loadingFile = true
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      // form.append("token", localStorage.getItem('token'));
      this.upload("/api/quote/upload_file", form).then((res) => {
        this.select_file_name = file.name
        this.quoteform.url = res.data.url
        this.quoteform.new_file_name = file.name
        this.loadingFile = false
      }).catch((err) => {
        this.$message({
          type: "error",
          message: err.message,
        });
        this.loadingFile = false
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    beforUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    addLine () {
      // if (this.quoteform.quote_char) {
      this.quoteform.quote_char.push({});
      console.log(this.quoteform)
      // }
    },
    handleDelete (index) {
      this.quoteform.quote_char.splice(index, 1);
    },
    getView () {
      this.axios.get('/api/quote/view', { params: { id: this.$route.query.id } }).then((res) => {
        this.quoteform = res.data
      })
    },
    getQuoteType () {
      if (this.quoteform.data_source == 1) {
        this.simple = true
        this.complex = false
      } else {
        this.simple = false
        this.complex = true
      }
    },
    onSubmit () {
      if (this.quoteform.url) {
        this.axios.post('/api/quote/store', this.quoteform).then((res) => {
          this.$message.success(res.message)
          this.$router.go(-1)
        }).catch((err) => {
          this.$message.error(err.message)
        })
      }
    },
  },
}
</script>

<style scoped>
.body {
  background: #f2f3f8;
  min-height: 100%;
}
.title {
  padding: 0 30px;
  margin-top: 20px;
}
.content {
  margin: 30px;
}
.el-select {
  width: 100%;
}
.from_flex .el-form-item {
  width: 23% !important;
}
.from_flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.from_upload {
  display: flex;
  /* align-items: center; */
}
.upload {
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 40px;
  margin-left: 3%;
}
.el-button--primary {
  width: 370px;
}
/* 隐藏垂直滚动条 */
.body ::-webkit-scrollbar {
  display: none;
}
.scroll ::-webkit-scrollbar {
  overflow-y: scroll;

  display: none;

  /* scroll-behavior: smooth; */
}
.scroll::-webkit-scrollbar {
  overflow-y: scroll;

  display: none;

  /* scroll-behavior: smooth; */
}
</style>